import { Element } from 'react-scroll';
import React, { Fragment, memo, useRef, useState, useEffect } from 'react';
import * as styles from './LeftSidebar.module.css';
import Awards from './sections/Awards';
import Certifications from './sections/Certifications';
import Education from './sections/Education';
import Hobbies from './sections/Hobbies';
import Languages from './sections/Languages';
import LeftNavbar from './LeftNavbar';
import Objective from './sections/Objective';
import Profile from './sections/Profile';
import Projects from './sections/Projects';
import References from './sections/References';
import Skills from './sections/Skills';
import Social from './sections/Social';
import Work from './sections/Work';
import sections from '../../../data/leftSections';

const getComponent = (id) => {
  switch (id) {
    case 'profile':
      return Profile;
    case 'social':
      return Social;
    case 'objective':
      return Objective;
    case 'work':
      return Work;
    case 'education':
      return Education;
    case 'projects':
      return Projects;
    case 'awards':
      return Awards;
    case 'certifications':
      return Certifications;
    case 'skills':
      return Skills;
    case 'hobbies':
      return Hobbies;
    case 'languages':
      return Languages;
    case 'references':
      return References;
    default:
      throw new Error();
  }
};

const SidebarSection = ({ id, event, closeClassName, onClick }) => {
  const Component = getComponent(id);

  return (
    <Fragment key={id}>
      <Element name={id}>
        <Component
          id={id}
          event={event}
          closeClassName={closeClassName}
          onClick={onClick}
        />
      </Element>
      <hr />
    </Fragment>
  );
};

const LeftSidebar = () => {
  const ref = useRef(null);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions(),
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  const handleOpenClick = () => {
    const leftSections = ref.current;
    if (!leftSections.className.includes(styles.showleftsect))
      leftSections.className += ` ${styles.showleftsect}`;
    leftSections.style.width = `${width - 140}px`;
  };
  useEffect(() => {
    handleOpenClick();
  }, []);

  const handleCloseClick = () => {
    const leftSections = ref.current;
    if (leftSections.className.includes(styles.showleftsect))
      leftSections.className = styles.container;
  };

  return (
    <div className="flex">
      <LeftNavbar onClick={(e) => handleOpenClick(e)} />

      <div ref={ref} id="LeftSidebar" className={styles.container}>
        {sections.map((x) => (
          <SidebarSection
            id={x.id}
            event={x.event}
            closeClassName={styles.btnclose}
            onClick={(e) => {
              handleCloseClick(x, e);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(LeftSidebar);
